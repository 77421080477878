/* Home container */
body {
  overflow: hidden;
}

.home {
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
  padding: 0 20px;
}

/* Interactive section wrapper */
.home .interactive-section-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* Interactive section */
.home .interactive-section {
  position: relative;
  width: 100%;
  height: 100%;
  transform: scale(0.75); /* Using transform instead of scale */
  display: grid;
  place-items: center;
}

/* Logo */
.home .logo-instance {
  width: 160px;
  height: 160px;
}

/* Text block */
.home .text-block {
  height: 60px;
  right: 0px;
  position: absolute;
  bottom: 0px;
  width: 81px;
}

/* Text wrapper */
.home .text-wrapper {
  color: #dbdcd5;
  font-family: "Space Grotesk-Regular", Helvetica;
  font-size: 23.7px;
  font-weight: 400;
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

/* Div */
.home .div {
  color: #dbdcd5;
  font-family: "Space Grotesk-Regular", Helvetica;
  font-size: 23.7px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 30px;
}

/* Text logo */
.home .textlogo {
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 127px;
}

/* Text wrapper 2 */
.home .text-wrapper-2 {
  color: #dbdcd5;
  font-family: "Space Grotesk-Regular", Helvetica;
  font-size: 23.7px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

/* Rectangle */
.home .rectangle {
  background-color: #d9d9d9;
  height: 1px;
  left: 61px;
  position: absolute;
  top: 45px;
  width: 62px;
}

/* Navigation indicator */
.home .nav-indicator {
  height: 100px; /* Adjusted height */
  right: 0px;
  position: absolute;
  top: 0;
  width: 143px;
}

/* Text wrappers */
.home .text-wrapper-home,
.home .text-wrapper-3,
.home .text-wrapper-4,
.home .text-wrapper-5 {
  color: #FF122E;
  font-family: "Space Grotesk-Bold", Helvetica;
  font-size: 23.7px;
  font-weight: 700;
  right: 30px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  cursor: pointer;
}

.home .text-wrapper-home {
  top: 0px;
}

.home .text-wrapper-3 {
  top: 30px;
}

.home .text-wrapper-4 {
  top: 60px;
}

.home .text-wrapper-5 {
  top: 90px;
}

/* Color block */
.home .color-block {
  height: 48px;
  left: 0;
  position: absolute;
  bottom: 0px;
  width: 44px;
}

/* Rectangle 2 */
.home .rectangle-2 {
  background-color: #FF122E;
  height: 24px;
  left: 0;
  position: absolute;
  bottom: 0;
  width: 44px;
}

/* Rectangle 3 */
.home .rectangle-3 {
  background-color: #58568b;
  height: 24px;
  left: 0;
  position: absolute;
  bottom: 24px;
  width: 44px;
}

/* Navigation button positions */
.home .nav-button {
  position: absolute !important;
  width: 11px !important;
  height: 22px !important;
  right: 0;
  transition: top 0.3s ease-in-out;
}

.home .nav-button.nav-button-home {
  top: 0px !important;
}

.home .nav-button.nav-button-about {
  top: 30px !important;
}

.home .nav-button.nav-button-links {
  top: 60px !important;
}

.home .nav-button.nav-button-swap {
  top: 90px !important;
}