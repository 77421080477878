.box {
  height: 329px;
  position: relative;
  width: 578px;
}

.linkswindow {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-group {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  font-family: "Space Grotesk-Regular", Helvetica;
  font-size: 20.4px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 20px;
}

.logo {
  margin: 0 20px;
}

.xlogo-instance {
  height: 50px !important;
  width: 50px !important;
}

.sniperlogo-instance {
  height: 60px !important;
  width: 60px !important;
}

.photonlogo-instance {
  height: 70px !important;
  width: 70px !important;
}