.about-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.about-text {
  font-family: "Space Grotesk-Regular", Helvetica;
  font-size: 12pt;
  color: #ffffff;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  display: inline-block;
  white-space: pre-wrap;
  height: 80px;
}

.infographic {
  margin-bottom: 0px;
  max-height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infographic2 {
  height: 300px;
}

.navigation-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.back-button,
.forward-button {
  cursor: pointer;
}