.popup-window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  height: 100%;
  max-height: 350px;
  background-color: #000000;
  border-radius: 1px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 999;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.popup-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  overflow: hidden;
  position: relative;
}

.bottomleftcorner,
.bottomrightcorner,
.topleftcorner,
.toprightcorner {
  height: 31px;
  position: absolute;
}

.bottomleftcorner {
  left: 0;
  bottom: 0;
  width: 36px;
}

.bottomrightcorner {
  right: 0;
  bottom: 0;
  width: 37px;
}

.topleftcorner {
  left: 0;
  top: 0;
  width: 36px;
}

.toprightcorner {
  right: 0;
  top: 0;
  width: 36px;
}

.overlap-group {
  height: 31px;
  position: relative;
}

.line {
  height: 3px !important;
  left: 0 !important;
  position: absolute !important;
  top: 28px !important;
  width: 36px !important;
}

.icon-instance-node,
.line-39,
.line-41 {
  height: 31px !important;
  position: absolute !important;
  top: 0 !important;
}

.icon-instance-node {
  left: 0 !important;
  width: 3px !important;
}

.line-39,
.line-41 {
  left: 34px !important;
  width: 3px !important;
}

.line-2 {
  height: 3px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 36px !important;
}

.line-42 {
  height: 3px !important;
  left: 0 !important;
  position: absolute !important;
  top: 28px !important;
  width: 37px !important;
}